import React, { useState } from "react";
import '../styles/whitelist.style.css';
import axios from "axios";
import {TextField} from "@mui/material";
const CryptoJS = require('crypto-js');

const PasswordForgot = () => {
    const [formData, setFormData] = useState({
        password: "",
        organizationPassword: "",
        organizationDisplayName: "",
        collaborators: ""
    });
    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token")
    const [userAdmin, setUserAdmin] = useState({})
    const [isCompleted, setIsCompleted] = useState(false)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const response = await axios.get(`https://enrapture.tech:6060/users/getUserByToken/${token}`);
            setUserAdmin(response.data);
            setIsCompleted(true);

            console.log(response.data);

            let form = {
                id_user: response.data.id,
                firstName : response.data.first_name,
                lastName : response.data.last_name,
                email: response.data.email,
                type_user: response.data.type_user,
                password: formData.password,
                website: response.data.website,
                linkedinUrl: response.data.linkedin_url
            }

            const update = await axios.post(`https://enrapture.tech:6060/users/updateUser`, form);
        }
        catch (error){
            //console.error("Error fetching data:", error);
        }
    };

    return (
        <>
            {
                isCompleted && (
                    <div className="FinishBlock">
                        <h1 className="FinishText">Thanks! Your password is changed !</h1>
                    </div>
                )
            }

            {
                !isCompleted && (
                    <>
                        <div className="block_content">
                            <form onSubmit={handleSubmit}>
                                <label>
                                    Change your password:
                                    <br/>
                                    <input
                                        type="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                </label>
                                <br />
                                <button type="submit">Confirm</button>
                            </form>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default PasswordForgot;
