import React, { useState } from "react";
import '../styles/whitelist.style.css';
import axios from "axios";
import {FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Switch} from "@mui/material";
import Button from "@mui/material/Button";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const WhitelistForm = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        website: "",
        purpose_of_use: "",
        linkedinUrl: "",
        average_users: 1,
        isSas: false,
        sas_reason: ""
    });

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [error, setError] = useState("")

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    function estUrlLinkedInValide(url) {
        // Vérifier si l'URL contient "linkedin.com"
        return url.includes("linkedin.com");
    }

    const handleClickPayment = ()=>{
        if (formData.average_users >= 1 && formData.average_users <= 5) {
            window.location.href = "https://buy.stripe.com/6oE16f5CG8673Pa8wx";
        } else if (formData.average_users >= 6 && formData.average_users <= 29) {
            window.location.href = "https://buy.stripe.com/14kdT14yCdqrdpK9AC";
        } else if (formData.average_users >= 30 && formData.average_users <= 49) {
            window.location.href = "https://buy.stripe.com/3cs2aj9SW5XZgBW4gj";
        } else if (formData.average_users >= 50 && formData.average_users <= 79) {
            window.location.href = "https://buy.stripe.com/bIY6qzc140DF2L6dQU";
        } else if (formData.average_users >= 80 && formData.average_users <= 99) {
            window.location.href = "https://buy.stripe.com/8wMbKT6GK9ab1H2eUZ";
        } else if (formData.average_users >= 100) {
            window.location.href = "https://buy.stripe.com/3csbKT8OS1HJ1H2006";
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(formData.linkedinUrl.length > 0){
            if (estUrlLinkedInValide(formData.linkedinUrl)) {
                sendForm()
            } else {
                setError("Linkedin url is invalid.")
            }
        }else{
            sendForm()
        }
    };

    const sendForm = async ()=>{
        let data = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            website: formData.website,
            purpose_of_use: formData.purpose_of_use,
            linkedinUrl: formData.linkedinUrl,
            average_users: formData.average_users,
            sas_reason: formData.sas_reason
        }

        try{
            const response = await axios.post(`https://enrapture.tech:6060/formUsers/createRequestUser`, data, {
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            setFormSubmitted(true);
            setError("")
        }
        catch (error){
            console.error("Error fetching data:", error);
        }
    }

    return (
        <div className="wrapper">
            <div className="wrapdewrap">
                <h1 className="Price">CREATE A PRIVATE METAVERSE OFFICE FOR YOUR TEAM</h1>
                <h3 className="Price">Your private, invite-only office. Protected by Company password and with access to exclusive collaborative generative ai Tools (For text, image and Skybox prompts during meetings).</h3>
                <h3 className="Price">Meetwork Premium (Company Office) starts at 30 USD Monthly and it depends on the size of your team. We will share more info in a personal email after submitting the form.</h3>
            </div>
            <div className="block_content">
                <form onSubmit={handleSubmit}>
                    <label>
                        First Name *
                        <input
                            required
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Last Name *
                        <input
                            required
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Email *
                        <input
                            required
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Website *
                        <input
                            required
                            type="text"
                            name="website"
                            value={formData.website}
                            onChange={handleChange}
                        />
                    </label>
                    <br/>
                    <label>
                        Linkedin URL
                        <input
                            type="text"
                            name="linkedinUrl"
                            value={formData.linkedinUrl}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Maximum Number of users in your meetings *
                        <input
                            required
                            type="number"
                            name="average_users"
                            value={formData.average_users}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Purpose of use *
                        <textarea
                            required
                            name="purpose_of_use"
                            value={formData.purpose_of_use}
                            onChange={handleChange}
                        />
                    </label>
                    <FormGroup>
                        <FormControlLabel control={<Switch />} onChange={handleChange} value={formData.isSas} name="isSas" label="Interested in making customized Office Look based on your vision? (We will reach out with an additional budget proposal from us)" />
                    </FormGroup>
                    {
                        formData.isSas && (
                            <>
                                <br />
                                <label>
                                    Write your vision *
                                    <textarea
                                        required
                                        name="sas_reason"
                                        value={formData.sas_reason}
                                        onChange={handleChange}
                                    />
                                </label>
                            </>
                        )
                    }
                    {/* {
                        formSubmitted === true && <p>Thanks for applying to the MeetWork waitlist! We will review your submission and send you an email if you are a good match. The email will allow you to sign up, register your company on the platform and give your team access to your AI Immersive Workroom!</p>
                    } */}
                    {
                        formSubmitted === true && (
                            <>
                                <p>Thank you, We have the information you need to create your organization. Proceed to payment to finalize your account creation.</p><br/>
                                <Button color="primary" onClick={e=> handleClickPayment()} startIcon={<ShoppingCartIcon />}>
                                    Proceed to payment
                                </Button>
                            </>
                        )
                    }
                    <br />
                    {
                        error && (
                            <p className="error-message">{error}</p>
                        )
                    }
                    {
                        formSubmitted == false && <button type="submit">Submit</button>
                    }
                </form>
            </div>
        </div>
    );
};

export default WhitelistForm;
