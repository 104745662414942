import '../styles/whitelist.style.css';
import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ButtonGroup from "@mui/material/ButtonGroup";
import React, {useEffect} from "react";
import HomeIcon from '@mui/icons-material/Home';
import LandscapeIcon from '@mui/icons-material/Landscape';
import VerifiedIcon from '@mui/icons-material/Verified';

const Redirection = () => {
    const GoToMeetwork = (e)=>{
        window.location.href = "https://meetwork.ai"
    }

    const GoToBuyLand = (e)=>{
        window.location.href = "https://www.enrapture.tech/landForm"
    }

    const GoToSubscribe = (e)=>{
        window.location.href = "https://www.enrapture.tech/form"
    }

    return (
        <div className="box-redirection">
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button onClick={x=>GoToMeetwork(x)} variant="outlined" startIcon={<HomeIcon />}>
                    Visit Meetwork.AI
                </Button>
                <Button onClick={x=>GoToBuyLand(x)} variant="outlined" startIcon={<LandscapeIcon />}>
                    Buy a virtual land
                </Button>
                <Button onClick={x=> GoToSubscribe(x)} variant="outlined" startIcon={<VerifiedIcon />}>
                    Subscribe to Meetwork.AI PREMIUM
                </Button>
            </ButtonGroup>
        </div>
    )
}

export default Redirection