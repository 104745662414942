import React, { useState } from "react";
import '../styles/whitelist.style.css';
import axios from "axios";
import {TextField} from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const SetupMember = () => {
    const [formData, setFormData] = useState({
        password: "",
        firstName: "",
        lastName : "",
        linkedinUrl: ""
    });
    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token")
    const [userAdmin, setUserAdmin] = useState({})
    const [isCompleted, setIsCompleted] = useState(false)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const response = await axios.get(`https://enrapture.tech:6060/users/getUserByToken/${token}`);
            setUserAdmin(response.data);
            setIsCompleted(true);
            let dataForm = {
                id_user : response.data.id,
                firstName : formData.firstName,
                lastName : formData.lastName,
                email : response.data.email,
                type_user : response.data.type_user,
                password : formData.password,
                website :response.data.website,
                linkedinUrl: formData.linkedinUrl
            }
            const update = await axios.post(`https://enrapture.tech:6060/users/updateUser`, dataForm);
        }
        catch (error){
            //console.error("Error fetching data:", error);
        }
    };

    return (
        <div className="wrapper">
            {
                isCompleted && (
                    <div className="FinishBlock">
                        <h1 className="FinishText">Thanks! Your information is saved !</h1>
                        <h2 className="FinishText">You are now welcome to open MeetWork and log in! Enjoy!</h2>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button variant="outlined" startIcon={<DownloadForOfflineIcon />}>
                                Download for Windows
                            </Button>
                            <Button variant="outlined" startIcon={<DownloadForOfflineIcon />}>
                                Download for Mac OS
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            }

            {
                !isCompleted && (
                    <>
                        <div className="block_content">
                            <form onSubmit={handleSubmit}>
                                <label>
                                    Create a member password *:
                                    <br/>
                                    <input
                                        type="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                </label>
                                <br/>
                                <TextField id="outlined-basic" required name="firstName" label="First Name" variant="outlined" value={formData.firstName} onChange={handleChange} /><br/><br/>
                                <TextField id="outlined-basic" required name="lastName" label="Last Name" variant="outlined" value={formData.lastName} onChange={handleChange} /><br/><br/>
                                <TextField id="outlined-basic" fullWidth={200} name="linkedinUrl" label="Linkedin profile" variant="outlined" value={formData.linkedinUrl} onChange={handleChange} /><br/><br/>
                                <button type="submit">Confirm</button>
                            </form>
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default SetupMember;
