import './App.css';
import WhitelistForm from "./components/WhitelistForm";
import { Routes ,Route } from 'react-router-dom';
import AdminPanel from "./components/AdminPanel";
import SetupAdmin from "./components/SetupAdmin";
import HomePage from "./components/HomePage";
import SetupMember from "./components/SetupMember";
import PasswordForgot from "./components/PasswordForgot";
import LandForm from "./components/LandForm";
import Redirection from "./components/Redirection";

function App() {
  return (
      <Routes>
          <Route exact path="/" element={<Redirection/>}/>
          <Route exact path="/form" element={<WhitelistForm/>}/>
          <Route exact path="/landForm" element={<LandForm/>}/>
          <Route exact path="/admin" element={<AdminPanel/>}/>
          <Route exact path="/setupAdmin" element={<SetupAdmin/>}/>
          <Route exact path="/setupMember" element={<SetupMember/>}/>
          <Route exact path="/recoverPassword" element={<PasswordForgot/>}/>
          <Route exact path="/homepage" element={<HomePage/>}/>
      </Routes>
  );
}

export default App;
