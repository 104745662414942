import React, { useState } from "react";
import '../styles/whitelist.style.css';
import axios from "axios";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Button from "@mui/material/Button";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Switch} from "@mui/material";

const LandForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        image_url: "",
        description: "",
        email: "",
        isSas: false,
        sas_reason: "",
        mapId: "2"
    });

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [error, setError] = useState("")

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        const newValue = type === 'radio' ? value : (type === 'checkbox' ? checked : value);

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: newValue,
        }));
    };

    const handleClickPayment = ()=>{
        window.location.href = "https://buy.stripe.com/7sIdT1aX0gCDdpK144"
    }

    function isValidEmail(email) {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    }


    function urlSeTermineParImage(url) {
        url = url.toLowerCase();
        return url.endsWith(".png") || url.endsWith(".jpeg") || url.endsWith(".jpg") || url.endsWith(".avif");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(urlSeTermineParImage(formData.image_url)){
            if(isValidEmail(formData.email)){
                let data = {
                    name: formData.name,
                    image_url: formData.image_url,
                    description: formData.description,
                    date_end: "",
                    nb_participants: 0,
                    nb_participants_max: 100,
                    room_id: formData.mapId,
                    active: 0,
                    email: formData.email,
                    sas_reason: formData.sas_reason
                }
                try{
                    const response = await axios.post(`https://enrapture.tech:6060/events/createEvent`, data, {
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
                    setFormSubmitted(true);
                }
                catch (error){
                    console.error("Error fetching data:", error);
                }
            }else{
                setError("Email is not valid")
            }
        }else{
            setError("Url image is not valid")
        }
    };

    return (
        <div className="wrapper">
            <div className="wrapdewrap">
                <h1 className="Price">CREATE YOUR NETWORKING LAND</h1>
                <h2 className="Price">45.00€ / yearly payment</h2>
                <h3 className="Price">Your Personal Land Open to Public for Networking, Events, Hackathons or Community Building.</h3>
            </div>
            <div className="block_content">
                <form onSubmit={handleSubmit}>
                    <label>
                        Land Name *
                        <input
                            required
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Land Image Url [JPG, JPEG, PNG, AVIF] (<a target="_blank" rel="noopener noreferrer" href="https://postimages.org/">Upload your image and Copy Paste the Direct Link</a>) *
                        <input
                            required
                            type="text"
                            name="image_url"
                            value={formData.image_url}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Land Description *
                        <textarea
                            required
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Your Email *
                        <input
                            required
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Custom your place
                        <br/>
                        <br/>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="mapId"
                            value={formData.mapId}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="2" control={<Radio />} defaultChecked label="Meet Field" />
                            <FormControlLabel value="5" control={<Radio />} label="Meet Hills" />
                        </RadioGroup>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <div className="images-map-container">
                            <img src={"ForestHub1.png"}/>
                            <img src={"Forest3.png"}/>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </label>
                    {
                        formSubmitted === true && (
                            <>
                                <p>Thank you, we have the information you need to create your land. Proceed to payment to finalize your land creation.</p><br/>
                                <Button color="warning" onClick={e=> handleClickPayment()} startIcon={<ShoppingCartIcon />}>
                                    Proceed to payment
                                </Button>
                            </>
                        )
                    }
                    <FormGroup>
                        <FormControlLabel control={<Switch />} onChange={handleChange} value={formData.isSas} name="isSas" label="Interested in making customized land based on your vision? (We will reach out with an additional budget proposal from us)" />
                    </FormGroup>
                    {
                        formData.isSas && (
                            <>
                                <br />
                                <label>
                                    Write your vision *
                                    <textarea
                                        required
                                        name="sas_reason"
                                        value={formData.sas_reason}
                                        onChange={handleChange}
                                    />
                                </label>
                            </>
                        )
                    }
                    <br />
                    {
                        formSubmitted === false && (
                            <button type="submit">Submit</button>
                        )
                    }
                    <br />
                    {
                        error && (
                            <p className="error-message">{error}</p>
                        )
                    }
                </form>
            </div>
        </div>
    );
};

export default LandForm;
