import React, { useState } from "react";
import '../styles/whitelist.style.css';
import axios from "axios";
import {TextField} from "@mui/material";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

const SetupAdmin = () => {
    const [formData, setFormData] = useState({
        password: "",
        organizationPassword: "",
        organizationDisplayName: "",
        collaborators: ""
    });
    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token")
    const [userAdmin, setUserAdmin] = useState({})
    const [isCompleted, setIsCompleted] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const response = await axios.get(`https://enrapture.tech:6060/users/getUserByToken/${token}`);
            setUserAdmin(response.data);
            let dataForm = {
                id_user : response.data.id,
                firstName : response.data.first_name,
                lastName : response.data.last_name,
                email : response.data.email,
                type_user : response.data.type_user,
                password : formData.password,
                website :response.data.website,
                linkedinUrl: response.data.linkedin_url
            }
            const update = await axios.post(`https://enrapture.tech:6060/users/updateUser`, dataForm);
            const OrganizationTest = await axios.get(`https://enrapture.tech:6060/organization/getOrganizationByName/${formData.organizationDisplayName}`);
            console.log(OrganizationTest.data)
            if(OrganizationTest.data == ""){
                const createOrg = await axios.get(`https://enrapture.tech:6060/organization/createOrganization/${response.data.id}/${formData.organizationDisplayName}/${formData.organizationPassword}`);
                const idOrganizationResponse = await axios.get(`https://enrapture.tech:6060/organization/getOrganizationByName/${formData.organizationDisplayName}`);
                let tabCollab = formData.collaborators.trim().split(',');
                //INSERT ADMIN IN ORGANIZATIOB
                await axios.get(`https://enrapture.tech:6060/organization-users/insertUserInOrganization/${response.data.id}/${idOrganizationResponse.data.id}`);
                for (const x of tabCollab) {
                    if(x != ""){
                        let dataForm = {
                            firstName : "unknown",
                            lastName : "unknown",
                            email : x,
                            type_user : "member",
                            password : "enrapture",
                            website : "unknown",
                            linkedinUrl: "unknown"
                        }
                        await axios.post(`https://enrapture.tech:6060/users/createMember`, dataForm);
                        const userLocal = await axios.get(`https://enrapture.tech:6060/users/getUser/${x}`);
                        //INSERT MEMBER IN ORGANIZATIOH
                        await axios.get(`https://enrapture.tech:6060/organization-users/insertUserInOrganization/${userLocal.data.id}/${idOrganizationResponse.data.id}`);
                    }
                }
                setIsCompleted(true);
            }else{
                setErrorMessage("Organization name already exist!")
            }
        }
        catch (error){
            //console.error("Error fetching data:", error);
        }
    };

    return (
        <div className="wrapper">
            {
                isCompleted && (
                    <div className="FinishBlock">
                        <h1 className="FinishText">Thanks! Your information is saved !</h1>
                        <h2 className="FinishText">You are now welcome to open MeetWork and log in! Enjoy!</h2>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button variant="outlined" startIcon={<DownloadForOfflineIcon />}>
                                Download for Windows
                            </Button>
                            <Button variant="outlined" startIcon={<DownloadForOfflineIcon />}>
                                Download for Mac OS
                            </Button>
                        </ButtonGroup>

                    </div>
                )
            }

            {
                !isCompleted && (
                    <>
                        <div className="block_content">
                            <form onSubmit={handleSubmit}>
                                <label>
                                    Create an admin password *:
                                    <br/>
                                    <input
                                        required
                                        type="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                </label>
                                <br />
                                <label>
                                    Create an organization password (For virtual office privacy) *:
                                    <input
                                        required
                                        type="password"
                                        name="organizationPassword"
                                        value={formData.organizationPassword}
                                        onChange={handleChange}
                                    />
                                    <p className="GreenText">You will need to safely share this to all employees or team members for them to access</p>
                                </label>
                                <br />
                                <label>
                                    Create an organization name:
                                </label>
                                <TextField id="outlined-basic" required name="organizationDisplayName" label="Organization Name" variant="outlined" value={formData.organizationDisplayName} onChange={handleChange} />
                                {
                                    errorMessage != "" && (
                                        <>
                                            <h2 className="error-message">The organization name already exist! Please choose a new name.</h2>
                                        </>
                                    )
                                }
                                <br />
                                <br />
                                <p className="GreenText">You will be able to add members of your team to the application after setting up your account.</p>
                                {/*<label>
                                    Invite collaborator email on your organization (separate with a ,):
                                </label>
                                <TextField
                                    id="filled-textarea"
                                    label="Invite Collaborator"
                                    placeholder="toto@gmail.com, tata@gmail.com"
                                    multiline
                                    variant="filled"
                                    name="collaborators"
                                    value={formData.collaborators}
                                    onChange={handleChange}
                                    fullWidth={true}
                                />
                                <br />*/}
                                <br />
                                <button type="submit">Confirm</button>
                            </form>
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default SetupAdmin;
