import '../styles/homepage.style.css';
import {Button} from "@mui/material";
const HomePage = () => {
    return (
        <>
            <div className="container">

                <div className="presentation-section">
                    <div className="header-navigation">
                        <div className="list-items">
                            <Button color="primary" variant="outlined">Join waitlist</Button>
                            <Button color="primary" variant="outlined">Blog</Button>
                            <Button color="primary" variant="outlined">Login</Button>
                        </div>
                    </div>

                    <div className="text-right">
                        <p className="text-presentation">ENABLING TEAMS TO SCALE NEW HEIGHTS WITH IMMERSIVE AI WORKROOMS THAT ENHANCE ENGAGEMENT, BONDING, PRODUCTIVITY AND TRANSPARENT MONITORING</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePage;