import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useEffect, useRef, useState} from "react";
import { DataGrid } from '@mui/x-data-grid';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {Button, ButtonGroup} from "@mui/material";
import axios from "axios";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [curMenu, setCurMenu] = useState("Approve")
    const [requests, setRequests] = useState([])
    const [gridRow, setGridRow] = useState()
    const [hasAccess, setHasAccess] = useState(false)

    const [formData, setFormData] = useState({
        password: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(formData.password == "cookandbook1200meet$"){
            setHasAccess(true)
        }
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(()=>{
        getAllRequests().then(r => {

        })
    }, [])

    const getAllRequests = async ()=>{
        try{
            const response = await axios.get(`https://enrapture.tech:6060/formUsers/getAllRequestNotApproved`);
            setRequests(response.data);
        }
        catch (error){
            //console.error("Error fetching data:", error);
        }
    }

    const GoTo = (index) => {
        if(index == 0){
            setCurMenu("Approve");
        }
        if(index == 1){
            setCurMenu("Other");
        }
    }

    const ApproveUsers = () => {
        if(gridRow != null){
            gridRow.map(async (i, index) => {
                let user = requests.find((x)=> x.id == i);
                const response = await axios.get(`https://enrapture.tech:6060/formUsers/updateRequest/${i}/1`);
                let dataForm = {
                    firstName : user.first_name,
                    lastName : user.last_name,
                    email : user.email,
                    type_user : "admin",
                    password : "123456",
                    website : user.website,
                    linkedinUrl: user.linkedin_url
                }
                const response2 = await axios.post(`https://enrapture.tech:6060/users/createUser`, dataForm);
                getAllRequests()
            })
        }
    }

    const RemoveUsers = () => {
        if(gridRow != null){
            gridRow.map(async (i, index) => {
                let user = requests.find((x)=> x.id == i);
                const response2 = await axios.get(`https://enrapture.tech:6060/formUsers/deleteRequest/${user.id}`);
                getAllRequests()
            })
        }
    }


    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'first_name', headerName: 'First name', width: 130 },
        { field: 'last_name', headerName: 'Last name', width: 130 },
        {
            field: 'email',
            headerName: 'Email',
            width: 250,
        },
        { field: 'website', headerName: 'Website', width: 200 },
        { field: 'purpose_of_use', headerName: 'Purpose of use', width: 500 },
        { field: 'is_approved', headerName: 'Approved', width: 140 },
        { field: 'average_users', headerName: 'Team Size', width: 140 },
        { field: 'sas_reason', headerName: 'Sas Reason', width: 140 }
    ];

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                {['Approve users', 'Log out'].map((text, index) => (
                    <ListItem key={text} disablePadding onClick={(e)=> GoTo(index)}>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <SupervisedUserCircleIcon /> : <ExitToAppIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            {
                hasAccess && (
                    <>
                        <Box sx={{ display: 'flex' }}>
                            <CssBaseline />
                            <AppBar
                                position="fixed"
                                sx={{
                                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                                    ml: { sm: `${drawerWidth}px` },
                                }}
                            >
                                <Toolbar>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        sx={{ mr: 2, display: { sm: 'none' } }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Typography variant="h6" noWrap component="div">
                                        Admin Panel
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Box
                                component="nav"
                                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                                aria-label="mailbox folders"
                            >
                                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                                <Drawer
                                    container={container}
                                    variant="temporary"
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                    sx={{
                                        display: { xs: 'block', sm: 'none' },
                                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                                <Drawer
                                    variant="permanent"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                    }}
                                    open
                                >
                                    {drawer}
                                </Drawer>
                            </Box>
                            {
                                curMenu == "Approve" && (
                                    <>
                                        <Box
                                            component="main"
                                            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                                        >
                                            <Toolbar />
                                            <Button variant="contained" onClick={x => ApproveUsers()}>Approve user(s)</Button>
                                            <Button color="warning" variant="contained" onClick={x => RemoveUsers()}>Remove user(s)</Button>
                                            <div style={{ height: 400, width: '100%', marginTop: 15 }}>
                                                <DataGrid
                                                    onRowSelectionModelChange={x=> setGridRow(x)}
                                                    rows={requests}
                                                    columns={columns}
                                                    initialState={{
                                                        pagination: {
                                                            paginationModel: { page: 0, pageSize: 5 },
                                                        },
                                                    }}
                                                    pageSizeOptions={[5, 10]}
                                                    checkboxSelection
                                                />
                                            </div>
                                        </Box>
                                    </>
                                )
                            }
                            {
                                curMenu == "Other" && (
                                    <>
                                        <Box
                                            component="main"
                                            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                                        >
                                            <Toolbar />
                                            <p>Test</p>
                                        </Box>
                                    </>
                                )
                            }
                        </Box>
                    </>
                )
            }

            {
                !hasAccess && (
                    <>
                        <form onSubmit={handleSubmit}>
                            <label>
                                Admin password:
                                <br/>
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </label>
                            <br />
                            <button type="submit">Connect</button>
                        </form>
                    </>
                )
            }
        </>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
